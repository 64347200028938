import React from "react";
import {FooterWrapper} from "./footer.style";
const Footer = () => {
    return (
           <FooterWrapper>
               <div className="lg-recoov-footer-container">
                   <div className="lg-recoov-footer-top">
                       <div className="lg-recoov-footer-illustration">
                           <div className="lg-recoov-footer-logo-box">
                               <a className="lg-recoov-footer-logo" href="#" target="_blank" rel="noopener noreferrer">
                                   <img className="lg-recoov-footer-logo-image" src="/assets/images/logo-recoov.png" loading="lazy" alt="logo de Legafrik"/>
                               </a>
                           </div>
                           <div className="lg-recoov-footer-text-box">
                               <p className="lg-recoov-footer-text">
                                   DATAGREFFE est une plateforme d'informations légales des entreprises en Côte d'Ivoire
                               </p>
                           </div>
                           <div className="lg-recoov-footer-social-box">
                               <ul className="lg-recoov-footer-social-list">
                                   <li>
                                       <a href="https://wa.me/22569564297">
                                           <i  className="fab fa-whatsapp fa-2x lg-recoov-footer-social-icon"></i>
                                       </a>
                                   </li>
                                   <li>
                                       <a href="https://www.facebook.com/Legafrikcompta">
                                           <i  className="fab fa-facebook fa-2x lg-recoov-footer-social-icon"></i>
                                       </a>
                                   </li>
                                   <li>
                                       <a href="https://www.linkedin.com/company/legafrikcompta/">
                                           <i  className="fab fa-linkedin fa-2x lg-recoov-footer-social-icon"></i>
                                       </a>
                                   </li>

                               </ul>
                           </div>
                       </div>

                       <div className="lg-recoov-footer-nav">
                           <div className="lg-recoov-footer-nav-item">
                               <h4 className="lg-recoov-footer-nav-title">Notre offre</h4>
                               <ul className="lg-recoov-footer-nav-list">
                                   <li className="lg-recoov-footer-nav-list-item">
                                       <a className="lg-recoov-footer-nav-list-link" href="#tarif">Tarifs</a>
                                   </li>
                               </ul>
                           </div>

                           <div className="lg-recoov-footer-nav-item">
                               <h4 className="lg-recoov-footer-nav-title">Adresse et contact</h4>
                               <ul className="lg-recoov-footer-nav-list">
                                   <li className="lg-recoov-footer-nav-list-item">
                                       <a className="lg-recoov-footer-nav-list-link" href="https://www.legafrik.com/legafrik-partenaire">Côte d’Ivoire,Abidjan</a>
                                   </li>
                               </ul>
                           </div>
                       </div>

                   </div>
                   <div className="lg-recoov-newsletter">
                       <div className="lg-recoov-newsletter-box-title">
                           <h4 className="lg-recoov-newsletter-title">Souscrivez à notre newsletter</h4>
                       </div>
                       <form action="" className="lg-recoov-newsletter-form">
                           <div className="lg-recoov-newsletter-form-content">
                               <input type="text" placeholder="Entrez votre e-mail"  className="lg-recoov-newsletter-form-input"/>
                               <button className="lg-recoov-newsletter-form-btn">ENVOYER</button>
                           </div>
                       </form>
                   </div>
               </div>
               </FooterWrapper>
    );
};

export default Footer;
